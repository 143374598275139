import { FC } from 'react';
import { SearchResult } from '@les-sherpas/sherpas-toolbox';

import LoginSignUpModalWrapper from '@/shared/LoginSignupModalWrapper/LoginSignupModalWrapper';
import SearchResultCard from '@/shared/SearchResultCard/SearchResultCard';
import createCheckoutRedirectUrl from '@/utils/functionsToClean/createCheckoutRedirectUrl';

import useStyles from './styles';

interface Props {
  announces: SearchResult[];
}

const GridItems: FC<Props> = ({ announces }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      {announces?.map((searchResult) => {
        const checkoutDmrUrl = createCheckoutRedirectUrl({
          announceId: searchResult.id,
          subject: searchResult.subjects[0],
        });
        return (
          <LoginSignUpModalWrapper
            key={searchResult.id}
            origin="announce"
            initialVariant="signup"
            drawerExitUrl={checkoutDmrUrl}
            teacher={searchResult.teacher}
            renderItem={({ toggle }) => (
              <SearchResultCard
                searchResult={searchResult}
                showLoginSignupModal={toggle}
                isFavoriteHidden
              />
            )}
          />
        );
      })}
    </div>
  );
};

export default GridItems;
