import { FC } from 'react';
import { Typography } from '@mui/material';

import NextLinkWithNoCss from '@/components/NextLinkWithNoCss/NextLinkWithNoCss';
import Icon from '@/designSystem/Icon/Icon';

import useStyles from './styles';

export type BreadcrumbItemProps = {
  content: string;
  label: string;
  isLastElement?: boolean;
  href?: string;
};

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({
  href = '',
  content,
  isLastElement = false,
  label,
}) => {
  const { classes } = useStyles({ isLastElement });
  return (
    <li
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/ListItem"
      className={classes.container}
    >
      {isLastElement ? (
        <Typography itemProp="name" component="span" variant="bodySmBold">
          {label}
        </Typography>
      ) : (
        <NextLinkWithNoCss href={href} itemProp="item">
          <Typography
            itemProp="name"
            component="span"
            variant="bodySmBold"
            className={classes.link}
          >
            {label}
          </Typography>
        </NextLinkWithNoCss>
      )}
      <meta itemProp="position" content={content} />
      {!isLastElement && <Icon icon="chevronRight" color="subtle" />}
    </li>
  );
};
export default BreadcrumbItem;
