import { TitleContent, TitleSubtitle } from '@les-sherpas/sherpas-toolbox';

import StartingSteps from '@/components/LandingMozza/common/StartingSteps/StartingSteps';
import SEOFormattedMessage from '@/generic/SEOFormattedMessage/SEOFormattedMessage';

export default function SeoStartingSteps({
  stepper,
  handleRedirection,
}: {
  stepper: TitleSubtitle & { items: TitleContent[] };
  handleRedirection: () => void;
}) {
  return (
    <StartingSteps
      title={
        stepper.title ? (
          <SEOFormattedMessage
            id="startingSteps.overloadedTitle"
            defaultMessage={stepper.title}
          />
        ) : undefined
      }
      subtitle={
        stepper.subtitle ? (
          <SEOFormattedMessage
            id="startingSteps.overloadedSubtitle"
            defaultMessage={stepper.subtitle}
          />
        ) : undefined
      }
      steps={
        stepper.items.length > 0
          ? stepper.items.map(({ title, content }, index) => ({
              title: (
                <SEOFormattedMessage
                  id={`startingSteps.steps.overloadedTitle${index}`}
                  defaultMessage={title}
                />
              ),
              content: (
                <SEOFormattedMessage
                  id={`startingSteps.steps.overloadedContent${index}`}
                  defaultMessage={content}
                />
              ),
            }))
          : undefined
      }
      handleRedirection={handleRedirection}
    />
  );
}
