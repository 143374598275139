import { makeStyles } from 'tss-react/mui';

type Props = {
  version?: string;
};

export default makeStyles<Props>()((theme, { version }) => ({
  green: {
    color: theme.palette.primary[version],
  },
}));
