import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'landingmozzaseo.teacher.title',
    defaultMessage: '',
  },
  subTitle: {
    id: 'landingmozzaseo.teacher.subtitle',
    defaultMessage: '',
  },
  cta: {
    id: 'landingmozzaseo.teacher.cta',
    defaultMessage: 'Voir tous les professeurs',
  },
});
