import { FC, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchResult } from '@les-sherpas/sherpas-toolbox';
import { Typography } from '@mui/material';

import ArticleHeader from '@/components/LandingMozza/common/ArticleHeader/ArticleHeader';
import SectionTitle from '@/components/LandingMozza/common/SectionTitle/SectionTitle';
import GridItems from '@/components/LandingMozza/LandingSeo/common/Teachers/GridItems/GridItems';
import Button from '@/designSystem/Button/Button';

import messages from './messages';

import useStyles from './styles';

type Props = {
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  announces: SearchResult[];
  handleRedirection: () => void;
};
const Teachers: FC<Props> = ({
  title,
  subtitle,
  announces,
  handleRedirection,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.container}>
      <ArticleHeader>
        <SectionTitle>
          {title ?? <FormattedMessage {...messages.title} />}
        </SectionTitle>
        <Typography component="p" variant="bodyXl" className={classes.subTitle}>
          {subtitle ?? <FormattedMessage {...messages.subTitle} />}
        </Typography>
      </ArticleHeader>
      <GridItems announces={announces} />
      <Button
        variant="primary"
        size="large"
        onClick={handleRedirection}
        label={formatMessage(messages.cta)}
      />
    </div>
  );
};
export default Teachers;
