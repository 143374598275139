import { DMR_DRAWER } from '@/data/navbar/navButtons';

const createCheckoutRedirectUrl = ({
  announceId,
  subject,
}: {
  announceId: number;
  subject: string;
}) => {
  const origin =
    typeof window === 'undefined'
      ? process.env.NEXT_PUBLIC_FRONT_URL
      : window.location.origin;
  return subject
    ? `${origin}${DMR_DRAWER}?id=${announceId}&subject=${subject}`
    : `${origin}${DMR_DRAWER}?id=${announceId}`;
};

export default createCheckoutRedirectUrl;
