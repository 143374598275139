import { defineMessages } from 'react-intl';

export default defineMessages({
  subject: {
    id: 'landingmozza.hero.searchbar.subject',
    defaultMessage: 'Matière',
  },
  placeholder: {
    id: 'landingmozza.hero.searchbar.placeholder',
    defaultMessage: 'Mathématiques, Philosophie, ...',
  },
  find: {
    id: 'landingmozza.hero.searchbar.find',
    defaultMessage: 'Trouver un professeur',
  },
});
