import { ReactElement } from 'react';

import Link from '@/components/Link';

const A_TAG_ATTRIBUTE = /<a[^>]*?href=(["'])?((?:.(?!\1|>))*.?)\1?[^>]*?>/gm;

type ExtractReturnType = {
  content: string;
  links?: {
    [key: string]: ReactElement;
  };
};

const extractLinkTags = (str: string): ExtractReturnType | null => {
  if (!str) return { content: '', links: null };

  const findTag = [...str.matchAll(A_TAG_ATTRIBUTE)];

  if (!findTag?.length) return { content: str, links: null };

  const links = {};
  let content = str.slice(); // Deep clone
  findTag.forEach((tag, i) => {
    // NOTE: generates link as: <a0>link name</a0>
    content = content
      .replace(tag[0], `<a${i}>`) // catches start html tag on first occurrence
      .replace('</a>', `</a${i}>`); // catches end html tag on first occurrence
    // NOTE: For each link parsed, generate its corresponding `aN` react-intl value

    const url = tag[2];
    links[`a${i}`] = (...chunks: string[]) => (
      <Link
        href={url}
        target={url.includes('sherpas.com') ? '_self' : '_blank'}
      >
        {chunks}
      </Link>
    );
  });

  return { content, links };
};

export default extractLinkTags;
