import { defineMessages } from 'react-intl';

export default defineMessages({
  espagnol: {
    id: 'landingmozza.hero.searchbar.tag.espagnol',
    defaultMessage: 'Espagnol',
  },
  physique: {
    id: 'landingmozza.hero.searchbar.tag.physique',
    defaultMessage: 'Physique',
  },
  maths: {
    id: 'landingmozza.hero.searchbar.tag.maths',
    defaultMessage: 'Maths',
  },
  histoire: {
    id: 'landingmozza.hero.searchbar.tag.histoire',
    defaultMessage: 'Histoire',
  },
  anglais: {
    id: 'landingmozza.hero.searchbar.tag.anglais',
    defaultMessage: 'Anglais',
  },
  francais: {
    id: 'landingmozza.hero.searchbar.tag.francais',
    defaultMessage: 'Français',
  },
  plus20: {
    id: 'landingmozza.hero.searchbar.tag.plus20',
    defaultMessage: '+20',
  },
  populaires: {
    id: 'landingmozza.hero.searchbar.tag.populaires',
    defaultMessage: 'Populaires',
  },
});
