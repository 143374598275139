import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ isLastElement: boolean }>()(({ palette }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: rem(8),
  },
  link: {
    color: palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
  },
}));
