import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import themeFoundation from '@/designSystem/theme';

type Props = {
  color: 'green' | 'red' | 'grey' | 'darkGreen' | 'white';
  variant?: 'bodySmBold' | 'bodySm' | 'bodyMd' | 'bodyMdBold';
};

const getColor = (color: Props['color']) => {
  switch (color) {
    case 'grey':
      return themeFoundation.palette.neutral[700];
    case 'red':
      return themeFoundation.palette.supporting.red.main;
    case 'darkGreen':
      return themeFoundation.palette.primary.main;
    case 'white':
      return themeFoundation.palette.common.white;
    case 'green':
    default:
      return themeFoundation.palette.primary[400];
  }
};

const useStyles = makeStyles<Props>()(({ palette, border }, { color }) => ({
  badge: {
    display: 'inline-flex',
    padding: `0 ${rem(8)}`,
    height: rem(24),
    alignItems: 'center',
    borderRadius: rem(24),
    color: color === 'white' ? palette.common.black : palette.common.white,
    backgroundColor: getColor(color),
    fontWeight: 'bold',
    marginLeft: rem(8),
    border: border.main,
    width: 'max-content',
  },
  typography: {
    color: color === 'white' ? palette.common.black : palette.common.white,
  },
}));

const Badge: FC<PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>> = ({
  color,
  children,
  className,
  variant = 'bodySmBold',
  onClick,
}) => {
  const { classes, cx } = useStyles({ color });
  return (
    <div
      className={cx(classes.badge, className)}
      onKeyDown={() => onClick}
      role="button"
      tabIndex={0}
      onClick={onClick}
    >
      <Typography
        variant={variant}
        component="span"
        className={classes.typography}
      >
        {children}
      </Typography>
    </div>
  );
};

export default Badge;
