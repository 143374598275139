import posthog, { JsonType } from 'posthog-js';

export const useFeatureFlag = <TPayload extends JsonType>(name: string) => {
  const featureFlag = posthog.getFeatureFlag(name);
  const payload = posthog.getFeatureFlagPayload(name) as TPayload;
  const isFeatureEnabled = posthog.isFeatureEnabled(name);

  return {
    featureFlag,
    payload,
    isFeatureEnabled,
  };
};
