import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: rem(4),
    flexWrap: 'wrap',
    padding: rem(12),
    [breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      padding: `${rem(12)} ${rem(24)}`,
    },
  },
  hideLabelIfMobile: {
    display: 'none',
    [breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  badge: {
    cursor: 'pointer',
  },
}));
