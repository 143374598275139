import { defineMessages } from 'react-intl';

export default defineMessages({
  price: {
    id: 'resultCard.price',
    defaultMessage: 'À partir de ',
  },
  contacter: {
    id: 'resultCard.contacter',
    defaultMessage: 'Contacter',
  },
  location: {
    id: 'resultCard.location',
    defaultMessage: `{location, select,
            online {En ligne}
            other {À domicile}
        }`,
  },
  taxReduction: {
    id: 'resultCard.taxReduction',
    defaultMessage: 'Éligible crédit d’impôt',
  },
});
