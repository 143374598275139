import { QuestionAnswer, Title } from '@les-sherpas/sherpas-toolbox';

import LandingFaq from '@/components/LandingMozza/common/LandingFaq/LandingFaq';
import SEOFormattedMessage from '@/generic/SEOFormattedMessage/SEOFormattedMessage';

export default function SeoLandingFaq({
  qa,
  handleRedirection,
}: {
  qa: Title & { items: QuestionAnswer[] };
  handleRedirection: () => void;
}) {
  return (
    <LandingFaq
      title={
        qa.title ? (
          <SEOFormattedMessage
            id="faq.overloadedTitle"
            defaultMessage={qa.title}
          />
        ) : undefined
      }
      qa={
        qa.items.length > 0
          ? qa.items.map(({ question, answer }, index) => ({
              question: (
                <SEOFormattedMessage
                  id={`faq.qa.overloadedQuestion${index}`}
                  defaultMessage={question}
                />
              ),
              answer: (
                <SEOFormattedMessage
                  id={`faq.qa.overloadedAnswer${index}`}
                  defaultMessage={answer}
                />
              ),
            }))
          : undefined
      }
      handleRedirection={handleRedirection}
    />
  );
}
