import { FC, PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';
import Green from '@/generic/Green/Green';
import LinkParsing from '@/utils/HTMLparsing';

type Props = {
  id: string;
  defaultMessage: string;
  values?: Record<string, ReactNode>;
};

// NOTE: dedicated to HTML parsing of elements from
// tables Content and Template of the database
export const SEODefaultChunks = {
  p: (...chunks) => <span>{chunks}</span>,
  em: (...chunks) => <em>{chunks}</em>,
  li: (...chunks) => <li>{chunks}</li>,
  ol: (...chunks) => <ol>{chunks}</ol>,
  ul: (...chunks) => <ul>{chunks}</ul>,
  /** Render text to primary colors */
  green: (chunks) => <Green>{chunks}</Green>,
  green300: (chunks) => <Green version="300">{chunks}</Green>,
  /** Manual 'to next line' command */
  enter: EnterChunk,
  /** Other font formatting tags */
  strong: (chunks) => <strong>{chunks}</strong>,
  b: (chunks) => <strong>{chunks}</strong>,
};

const SEOFormattedMessage: FC<PropsWithChildren<Props>> = ({
  id,
  defaultMessage,
  values = {},
}) => {
  const { content: formatted, links } = LinkParsing(defaultMessage);
  return (
    <FormattedMessage
      values={{ ...SEODefaultChunks, ...values, ...links }}
      id={id}
      defaultMessage={formatted}
    />
  );
};

export default SEOFormattedMessage;
