import { FC, ReactNode } from 'react';

import useStyles from './styles';

export type BreadcrumbProps = FC<{
  children: ReactNode;
}>;

const Breadcrumb: BreadcrumbProps = ({ children }) => {
  const { classes } = useStyles();
  return (
    <nav aria-label="breadcrumb" className={classes.breadcrumb}>
      <ol
        itemScope
        itemType="https://schema.org/BreadcrumbList"
        className={classes.list}
      >
        {children}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
