import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    padding: `${rem(8)} ${rem(16)}`,
    background: 'rgba(24, 24, 27, 0.08)',
    borderRadius: rem(8),
    overflowY: 'auto',
    [breakpoints.up('sm')]: {
      width: 'fit-content',
    },
  },
  list: {
    listStyleType: 'none',
    display: 'flex',
    gap: rem(8),
    alignItems: 'center',
    margin: 0,
    padding: 0,
    whiteSpace: 'nowrap',
  },
}));
