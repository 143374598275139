import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  priceWithDiscount: {
    textDecoration: 'line-through',
    color: palette.neutral[500],
    marginRight: rem(4),
  },
  unit: {
    color: palette.neutral[500],
  },
}));
