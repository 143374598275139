/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import clsx from 'clsx';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useRouter } from 'next/router';

type NextComposedProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
> &
  NextLinkProps;

const NextComposed = React.forwardRef<HTMLAnchorElement, NextComposedProps>(
  (props, ref) => {
    const { as, href, replace, scroll, passHref, shallow, prefetch, ...other } =
      props;

    return (
      <NextLink
        href={href}
        prefetch={prefetch}
        as={as}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
        ref={ref}
        {...other}
      />
    );
  }
);
NextComposed.displayName = 'NextComposed';

interface LinkPropsBase {
  activeClassName?: string;
  innerRef?: React.Ref<HTMLAnchorElement>;
  naked?: boolean;
}

type LinkProps = LinkPropsBase & NextComposedProps & Omit<MuiLinkProps, 'href'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props: LinkProps, ref) => {
    const {
      href,
      activeClassName = 'active',
      className: classNameProps,
      naked,
      ...other
    } = props;

    const router = useRouter();
    const pathname = typeof href === 'string' ? href : href.pathname;
    const className = clsx(classNameProps, {
      [activeClassName]: router.pathname === pathname && activeClassName,
    });

    if (naked) {
      return (
        <NextComposed className={className} ref={ref} href={href} {...other} />
      );
    }

    return (
      <MuiLink
        component={NextComposed}
        className={className}
        ref={ref}
        href={href as string}
        {...other}
      />
    );
  }
);

export default Link;
Link.displayName = 'Link';
