import { FC } from 'react';
import { Typography } from '@mui/material';

import { formatPrice } from '@/shared/SearchResultCard/utils';

import useStyles from './styles';

type Props = {
  hourlyPriceWithDiscount: number;
  hourlyPriceWithTaxReduction: number;
};

const HourlyPriceWithTaxReduction: FC<Props> = ({
  hourlyPriceWithDiscount,
  hourlyPriceWithTaxReduction,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="bodySm" className={classes.priceWithDiscount}>
        {formatPrice(hourlyPriceWithDiscount)}
        <span className={classes.unit}>/h</span>
      </Typography>
      <Typography variant="bodyLgBold">
        {formatPrice(hourlyPriceWithTaxReduction)}
        <span className={classes.unit}>/h</span>
      </Typography>
    </div>
  );
};

export default HourlyPriceWithTaxReduction;
