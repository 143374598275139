import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette }) => ({
  container: {
    background: palette.common.white,
    padding: rem(4),
    paddingTop: rem(20),
    borderRadius: rem(16),
    cursor: 'pointer',
    '&:hover': {
      background: palette.neutral.light,
    },
  },
  stretch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infos: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: rem(4),
  },
  star: {
    width: rem(16),
    Height: rem(16),
    color: palette.supporting.amber[500],
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(8),
    padding: `${rem(16)} ${rem(12)}`,
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  onlinePricing: {
    display: 'flex',
    flexDirection: 'row',
    gap: rem(4),
    alignItems: 'baseline',
  },
}));
