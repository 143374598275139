import {
  formatCurrency,
  getPriceFromCentimes,
} from '@les-sherpas/sherpas-toolbox';

export const formatPrice = (price: number) =>
  formatCurrency({
    amount: getPriceFromCentimes(price),
    stripDecimalIfInteger: false,
    showSymbol: true,
  });
