import { useIntl } from 'react-intl';
import { TitleContent } from '@les-sherpas/sherpas-toolbox';

import { DynamicSeoText } from '@/components/LandingMozza/common/SeoText/SeoText';
import SEOFormattedMessage, {
  SEODefaultChunks,
} from '@/generic/SEOFormattedMessage/SEOFormattedMessage';

export default function SeoTextBlock({ seo }: { seo: TitleContent }) {
  const { formatMessage } = useIntl();
  return (
    seo.title &&
    seo.content && (
      <DynamicSeoText
        title={
          <SEOFormattedMessage
            id="seoText.overloadedTitle"
            defaultMessage={seo.title}
          />
        }
        content={formatMessage(
          {
            id: 'seoText.overloadedContent',
            defaultMessage: seo.content,
          },
          {
            ...SEODefaultChunks,
          }
        )}
      />
    )
  );
}
