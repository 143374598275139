import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  locationOfCourse,
  SearchResult,
  UserType,
} from '@les-sherpas/sherpas-toolbox';
import { Typography } from '@mui/material';

import useAuthentication from '@/components/Authentication/useAuthentication';
import Button from '@/designSystem/Button/Button';
import useAnnounceClickCounter from '@/hooks/useAnnounceClickCounter';
import useNavigation from '@/shared/Hooks/useNavigation';
import Hover from '@/shared/Hover/Hover';
import Permission from '@/shared/Permission/Permission';
import CardInfos from '@/shared/SearchResultCard/CardInfos/CardInfos';
import HourlyPriceWithDiscount from '@/shared/SearchResultCard/HourlyPriceWithDiscount/HourlyPriceWithDiscount';
import HourlyPriceWithTaxReduction from '@/shared/SearchResultCard/HourlyPriceWithTaxReduction/HourlyPriceWithTaxReduction';
import TaxReductionLabel from '@/shared/TaxReductionLabel/TaxReductionLabel';
import TeacherPicture from '@/shared/TeacherPicture/TeacherPicture';
import handleSendEventGTM from '@/utils/googleTagManagerHelper';
import { useIsMobile } from '@/utils/useResponsive';

import messages from './messages';

import useStyles from './styles';

type Props = {
  searchResult: SearchResult;
  showLoginSignupModal?: () => void;
  isFavoriteHidden?: boolean;
};

const SearchResultCard: FC<PropsWithChildren<Props>> = ({
  searchResult,
  showLoginSignupModal = () => {},
  isFavoriteHidden = false,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const { incrementVisitedAnnounceCounter } = useAnnounceClickCounter();
  const { authentication } = useAuthentication();
  const isMobile = useIsMobile();
  const { pushTo, urls, query } = useNavigation();

  const {
    id: announceId,
    isFavoriteAnnounce,
    teacher,
    seoId,
    title,
    locations,
    subjects,
    pricing,
  } = searchResult;
  const { subject: searchedSubjectName } = query;
  const subjectName = (searchedSubjectName as string) ?? subjects?.[0];

  const {
    firstName,
    rating = 0,
    isVerifiedAndTrained,
    city,
    reviewsCount,
    minutesDone,
  } = teacher;

  const location = locations
    .map((l) =>
      formatMessage(messages.location, {
        location: l,
      })
    )
    .join(', ');

  const isAtHomeOnly = locations.every((location) => location === 'atHome');

  const redirectToAnnouncePage = () => {
    const urlToAnnounce = urls.announce({
      seoId,
      searchParams: { subjectName },
    });
    if (isMobile) {
      pushTo(urlToAnnounce);
    } else {
      window.open(urlToAnnounce, '_blank');
    }
  };

  const redirectToPaymentPage = () => {
    pushTo(urls.payment({ id: announceId, subjectName }));
  };

  const sendGTMEvent = ({
    origin,
  }: {
    origin: 'contactButton' | 'favoriteButton' | 'searchResultCard';
  }) => {
    handleSendEventGTM('select_teacher', {
      user_id: authentication?.authData?.id,
      teacher_id: teacher.id,
      announce_id: announceId,
      subjects,
      review: teacher.rating,
      origin,
    });
  };

  const handleCardClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    sendGTMEvent({ origin: 'searchResultCard' });
    if (!authentication?.isAuthenticated) {
      incrementVisitedAnnounceCounter(announceId);
    }
    redirectToAnnouncePage();
  };

  const handleContactClick: MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.stopPropagation();
    sendGTMEvent({ origin: 'contactButton' });
    if (!authentication?.isAuthenticated) {
      showLoginSignupModal();
      incrementVisitedAnnounceCounter(announceId);
      return;
    }

    if (authentication?.authData?.userType === UserType.Teacher) {
      redirectToAnnouncePage();
      return;
    }

    redirectToPaymentPage();
  };

  const handleFavoriteClick = () => {
    sendGTMEvent({ origin: 'favoriteButton' });
  };

  const isDeductible =
    locations.includes(locationOfCourse.atHome) &&
    pricing.atHome.isEligibleNova;

  return (
    <Hover
      renderItem={({ isHovered }) => (
        <div
          className={classes.container}
          onClick={handleCardClick}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <TeacherPicture
            announceId={announceId}
            isFavoriteAnnounce={isFavoriteAnnounce}
            teacher={teacher}
            isHovered={isHovered}
            userType={authentication?.authData?.userType}
            onFavoriteClick={handleFavoriteClick}
            isFavoriteHidden={isFavoriteHidden}
          />
          <div className={classes.body}>
            <CardInfos
              firstName={firstName}
              city={city}
              rating={rating}
              reviews={reviewsCount}
              minutes={minutesDone}
              isVerifiedAndTrained={isVerifiedAndTrained}
            />
            <div>
              <div className={classes.title}>
                <Typography variant="bodyMd" component="span">
                  {title}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="bodyMd"
                  component="span"
                  color="text.secondary"
                >
                  {location}
                </Typography>
              </div>
            </div>
            <div>
              <div className={classes.stretch}>
                <div>
                  {isDeductible ? (
                    <>
                      <HourlyPriceWithTaxReduction
                        hourlyPriceWithDiscount={
                          pricing.atHome.hourlyPriceWithDiscount
                        }
                        hourlyPriceWithTaxReduction={
                          pricing.atHome.hourlyPriceWithTaxReduction
                        }
                      />
                      <TaxReductionLabel
                        text={formatMessage(messages.taxReduction)}
                      />
                    </>
                  ) : (
                    <Typography
                      variant="bodyMd"
                      component="span"
                      className={classes.onlinePricing}
                    >
                      <FormattedMessage {...messages.price} />
                      <HourlyPriceWithDiscount
                        hourlyPriceWithDiscount={
                          isAtHomeOnly
                            ? pricing.atHome.hourlyPriceWithDiscount
                            : pricing.online.hourlyPriceWithDiscount
                        }
                      />
                    </Typography>
                  )}
                </div>
                <div className={classes.stretch}>
                  <Permission
                    permission="payment-trial-course:write"
                    isAppliedOnlyWhenConnected
                  >
                    <Button
                      variant="primary"
                      onClick={handleContactClick}
                      label={formatMessage(messages.contacter)}
                    />
                  </Permission>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default SearchResultCard;
