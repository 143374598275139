import { SearchResult, TitleSubtitle } from '@les-sherpas/sherpas-toolbox';

import Teachers from '@/components/LandingMozza/LandingSeo/common/Teachers/Teachers';
import SEOFormattedMessage from '@/generic/SEOFormattedMessage/SEOFormattedMessage';

export default function SeoTeachers({
  teachers,
  announces,
  handleRedirection,
}: {
  teachers: TitleSubtitle;
  announces: SearchResult[];
  handleRedirection: () => void;
}) {
  return (
    <Teachers
      title={
        teachers.title ? (
          <SEOFormattedMessage
            id="teachers.overloadedTitle"
            defaultMessage={teachers.title}
          />
        ) : undefined
      }
      subtitle={
        teachers.subtitle ? (
          <SEOFormattedMessage
            id="teachers.overloadedSubtitle"
            defaultMessage={teachers.subtitle}
          />
        ) : undefined
      }
      announces={announces}
      handleRedirection={handleRedirection}
    />
  );
}
