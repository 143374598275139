import { FC, PropsWithChildren } from 'react';

import useStyles from './styles';

type Props = {
  version?: string;
};

const Green: FC<PropsWithChildren<Props>> = ({ children, version = '400' }) => {
  const { classes } = useStyles({ version });
  return <span className={classes.green}>{children}</span>;
};

export default Green;
